<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2"><h3>{{titlemodal}}</h3>
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="box-body">
                        <div class="border-b-0 border-gray-200 dark:border-white/10">
                            <nav class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" aria-label="Tabs">
                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 active"
                                        id="hs-tab-js-behavior-item-1" data-hs-tab="#hs-tab-js-behavior-1" aria-controls="hs-tab-js-behavior-1">
                                    Information de base
                                </button>



                            </nav>
                        </div>

                        <div class="">
                            <div id="hs-tab-js-behavior-1" role="tabpanel" aria-labelledby="hs-tab-js-behavior-item-1" class="">


                                <div v-if="messagesucces!==''" class="mt-4 mb-4 alert alert-success !border-success/10 flex items-center" role="alert">

                                    <div>
                                       {{messagesucces}}
                                    </div>
                                </div>

                                <div v-if="messageerror!==''" class="mt-4 mb-4 alert alert-danger !border-danger/10 flex items-center" role="alert">

                                    <div>
                                       {{messageerror}}
                                    </div>
                                </div>


                                <div class="alert alert-info text-center mt-3">
                                    <p style="color: black;font-size: 15px;">Date : {{Items.datedoc}} </p>
                                </div>





                                <div class="ti-custom-validation mt-5" >
                                    <div class="grid lg:grid-cols-2 gap-6">

                                        <div class="space-y-2">
                                            <div class="grid grid-cols-12  gap-x-6 mt-3">

                                                <div class="xl:col-span-10  col-span-12" >
                                                    <label v-if="typedoc==='DPCC'" class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Client</label>
                                                    <label v-else class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Bénéficiaire</label>

                                                    <input :placeholder="'Veuillez sélectionner un '+(typedoc==='DPCC'?'client':'Bénéficiaire')"  v-if="Items.beneficiaire===null" type="text" class="form-control" disabled>
                                                    <input v-else :value="Items.beneficiaire.name" type="text" class="form-control" disabled>
                                                </div>
                                                <div class="xl:col-span-2  col-span-12">
                                                    <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">&nbsp;</label>

                                                    <button   @click="btnmodallistedescltfrs()"   class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>


                                                </div>
                                            </div>
                                        </div>

                                        <div class="space-y-2">
                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Paramétre d'indication</label>

                                            <select v-model="Items.params.param1" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                                <option  :value="null">Paramétre d'indication</option>
                                                <option v-for="(datavalue,index) of alldata.paramcaisse" :key="index"  :value="datavalue">{{datavalue.name}}</option>

                                            </select>
                                        </div>



                                    </div>



                                    <div v-if="typedoc==='DEP'" class="space-y-2">
                                        <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Paramétre d'indication</label>

                                        <select v-model="paramjustification.typdoc" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                            <option  value="0">Dépense sans justificatif (facture manquante)</option>
                                            <option  value="1">Dépense avec justificatif</option>


                                        </select>
                                    </div>

                                    <div v-if="parseFloat(paramjustification.typdoc)===1 && typedoc==='DEP'" class="mt-5 grid lg:grid-cols-2 gap-6">



                                        <div class="space-y-2">
                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Référence de la facture</label>
                                            <input   v-model="paramjustification.numfacture"  type="text"   class="form-control">
                                        </div>

                                        <div class="space-y-2">
                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Montant TVA</label>
                                            <input   v-model="paramjustification.montanttva"  type="number"   class="form-control">
                                        </div>

                                        <div class="space-y-2">
                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Taux AIB</label>

                                            <select v-model="paramjustification.tauxaib" class="my-auto ti-form-input  rounded-sm ti-form-select rounded-sm !py-2 !px-3">
                                                <option  value="0"></option>
                                                <option  value="1">1%</option>
                                                <option  value="3">3%</option>
                                                <option  value="5">5%</option>


                                            </select>


                                        </div>
                                        <div class="space-y-2">
                                            <label class="text-capitalize ti-form-label dark:text-defaulttextcolor/70 mb-0">Montant AIB</label>
                                            <input   v-model="paramjustification.montantaib"  type="number"   class="form-control">


                                        </div>



                                    </div>




                                    <div class="space-y-2 mt-5">
                                        <div class=" mt-2">
                                            <label for="mvtobservation">Raison / Motif : </label>
                                            <textarea rows="3" id="mvtobservation" maxlength="225"
                                                      v-model="Items.docobservation"  type="text"
                                                      class="form-control"></textarea>
                                        </div>


                                    </div>

                                    <div class="space-y-2 mt-5">
                                        <div class=" mt-2">
                                            <label >Montant : </label>

                                            <input   v-model="Items.montant"  type="number"   class="form-control">
                                        </div>


                                    </div>



                                </div>
                            </div>



                        </div>
                    </div>




                </div>
                <div class="ti-modal-footer">
                    <button style="background: red" @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" >
                        Fermer
                    </button>
                    <button style="background: green" v-if="typeaction==='add'"  class="ti-btn ti-btn-primary-full" @click="saveItems">
                       Enregistrer
                    </button>
                    <button style="background: green" v-if="typeaction==='update'" class="ti-btn ti-btn-primary-full" @click="updateItems">
                       Modifier
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>

    <modallistfrsclt @elementSelectionnecltfrs="handleElementSelectedcltfrs" ref="modallistfrsclt"></modallistfrsclt>
    <validationouinon ref="validationouinon" @aftervalidation="aftervalidation"></validationouinon>
    <modalrapportdeprefdpcc ref="modalrapportdeprefdpcc" @closed="close" ></modalrapportdeprefdpcc>


</template>

<script>
    /*import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/definition/menu";*/
   // import allfunction from "@/allfunction";
    import {currencyFormatDE, isset, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    import modallistfrsclt from "@/components/views/pages/gestioncommerciale/achatvente/modal_listfrsclt";
    import validationouinon from "@/components/views/pages/caisse/depense/validationouinon";
    import modalrapportdeprefdpcc from "@/components/views/pages/caisse/rapport/modal_rapport_dep_ref_dpcc";


    const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "modalform",
        watch: {


        },

        computed:{

            beneficiairename(){
                let mvalue="";

                if (this.Items.beneficiaire!==null){
                    mvalue= this.Items.beneficiaire.name;

                }

                return mvalue

            }


        },
        components: {
            modallistfrsclt,
            modalrapportdeprefdpcc,
            validationouinon

        },

        data() {
            return {
                isdisplay:false,
                messageerror:"",
                messagesucces:"",
                titlemodal:"",
                alldata:{},
                typedoc:"",
                authoriationplslignedepense:0,
                typeaction:"add",
                Items: {},
                paramjustification:{
                    typdoc:0,
                    numfacture:'',
                    montanttva:0,
                    montantaib:0,
                    tauxaib:0,
                }
            };
        },
        methods: {


            handleElementSelectedcltfrs(elementSelectionne) {


                this.Items.beneficiaire=elementSelectionne.value




            },

            btnmodallistedescltfrs(){


                let listedatable=[],
                    titlemodal="",
                    option=this.typedoc,
                    typedoc="";

                if (option==='DPCC'){
                    titlemodal="Liste des clients"
                    listedatable=this.alldata.beneficiairecl
                    option='vente'
                }else{

                    titlemodal="Liste des bénéficiaires"
                    listedatable=this.alldata.beneficiaire
                    option='pers'
                }


                //this.$refs.modallistfrsclt.show(titlemodal,listedatable,this.typededocument,this.datainputlibelleforms);

                let $CUSTOMLIBELLEFRSCLT=JwtService.getCUSTOMLIBELLEFRSCLT()


                if (!isset($CUSTOMLIBELLEFRSCLT)){
                    $CUSTOMLIBELLEFRSCLT=JSON.parse($CUSTOMLIBELLEFRSCLT);

                }
                else{

                    $CUSTOMLIBELLEFRSCLT=this.$store.state.appcustomlibellefrsclt;

                    JwtService.setCUSTOMLIBELLEFRSCLT(JSON.stringify($CUSTOMLIBELLEFRSCLT))


                }


                this.$refs.modallistfrsclt.show(titlemodal,listedatable,option,this.datainputlibelleforms,$CUSTOMLIBELLEFRSCLT);

            },

            aftervalidation(elementSelectionne){

               this.Items.docnum=elementSelectionne.cnumdoc

                let t=this;



                if (t.typedoc==='DPCC' || t.typedoc==='REF'){
                    t.Items.alldepense.push({motif:t.Items.docobservation, montant:t.Items.montant});
                }


                let tabvalue={
                    nbon:"",
                    numdoc:t.Items.docnum,
                    date:t.Items.datedoc,
                    montanttotal:t.Items.montant,
                    montanttotaldelafacure:t.Items.montant,
                    items:t.Items.alldepense,
                    docsaisiepar:t.$store.state.appusername,
                    benrficiaire:t.Items.beneficiaire.name,
                    qrr:t.Items.docnum,
                    patient:t.Items.beneficiaire.name,
                    observations:t.Items.docobservation,
                    typdoc:t.typedoc


                }



                this.$refs.modalrapportdeprefdpcc.show(tabvalue);



            },

            close(){

                this.isdisplay=false;

                this.$emit('closed');

            },

            show(titlemodal,typeaction,typedoc,Items,alldata,authoriationplslignedepense,datainputlibelleforms){

                this.paramjustification={
                    typdoc:0,
                    numfacture:'',
                    montanttva:0,
                    montantaib:0,
                    tauxaib:0,
                }

                this.titlemodal=titlemodal
                this.typeaction=typeaction
                this.typedoc=typedoc
                this.Items=Items
                this.alldata=alldata
                this.authoriationplslignedepense=authoriationplslignedepense
                this.datainputlibelleforms=datainputlibelleforms
                this.messagesucces="";
                this.messageerror="";

                this.isdisplay=true;

            },

            currencyFormatDE(p1){
                var number=Number(p1)
                return currencyFormatDE(number)
            },

              async  saveItems(){

                   // this.$store.dispatch('lancerchargement',true)

                  if (this.Items.beneficiaire===null ||
                      this.Items.datedoc===""  ||  this.Items.docobservation===""  ||
                      this.Items.montant===0){

                      toastr['error']( 'Veuillez saisir toutes les informations',"<b></b>");
                    //  this.$store.dispatch('lancerchargement',false)
                      return
                  }


                  if (this.typedoc==='DEP'){

                      if (parseFloat(this.$store.state.montantencaisse)<parseFloat(this.Items.montant) ){
                          toastr['error']( "Votre solde en caisse n'est pas suffissant pour régler la" +
                              " dépense. Montant en caisse : "+this.currencyFormatDE(this.$store.state.montantencaisse),"<b></b>");
                          //this.$store.dispatch('lancerchargement',false)
                          return
                      }


                  }


                  if (this.typedoc==='DEP' && this.authoriationplslignedepense===0){
                      this.Items.alldepense=[];
                      this.Items.alldepense.push({

                          param:{pid:'',name:''},
                          motif:this.Items.docobservation,
                          montant:this.Items.montant

                      })


                      this.Items.param2=this.paramjustification


                  }

                  let titlemodal="";

                  if (this.typedoc==='DEP'){

                      titlemodal=`

                      <h4>
                                Voulez-vous vraiment autoriser la dépense de <span style="color: red;font-weight: bold;"> ${this.currencyFormatDE(this.Items.montant)} </span>
                                au nom de <span style="color: black;font-weight: bold;">${this.beneficiairename} ?</span>  </h4>

                      `;

                  }

                  if (this.typedoc==='REF'){

                      titlemodal=`

                      <h4>
                                Voulez-vous vraiment enregistrer ce retour de fonds de <span style="color: red;font-weight: bold;"> ${this.currencyFormatDE(this.Items.montant)} </span>
                                au nom de <span style="color: black;font-weight: bold;">${this.beneficiairename} ?</span>  </h4>

                      `;

                  }

                  if (this.typedoc==='DPCC'){

                      titlemodal=`

                      <h4>
                                Voulez-vous vraiment enregistrer ce dépôt client de <span style="color: red;font-weight: bold;"> ${this.currencyFormatDE(this.Items.montant)} </span>
                                au nom de <span style="color: black;font-weight: bold;">${this.beneficiairename} ?</span>  </h4>

                      `;

                  }



                  this.$refs.validationouinon.show(titlemodal,this.Items,this.typedoc);











              },


              async  updateItems(){

                    this.$store.dispatch('lancerchargement',true)

                    if (this.Items.intitule==="" ){

                        toastr['error']( 'Veuillez saisir toutes les informations',"<b></b>");
                        this.$store.dispatch('lancerchargement',false)
                        return

                    }


                  const t = this;
                  let url=process.env.VUE_APP_API_URL+'/egl745874pdefinition771714dfe/tier/maj/'+this.typedoc+'/'+this.$store.state.appsociete+'/'+randomString();
                  let response =   axios  .post(url,this.Items,{
                      headers: {
                          'X-Requested-With': 'XMLHttpRequest',
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                          'Authorization': 'Bearer ' + JwtService.getToken(),
                          'csrftoken': this.$store.state.appsociete
                      } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                  })

                  let res= await response
                        .then(function (res){
                           // t.$store.dispatch('beartoken',res.data.beartoken)
                            t.$store.dispatch('lancerchargement',false)
                            if(res.data.success){

                                t.messagesucces="";
                                t.messageerror="";

                                t.messagesucces=res.data.message;

                                toastr['success']( t.messagesucces,"<b></b>");


                                t.close()

                            }
                            else{

                               let errormessage="";

                                t.messageerror="";
                                t.messagesucces="";
                                if (res.data.datamessage.length>0){

                                    for (let message of res.data.datamessage){

                                        if (errormessage===""){

                                            errormessage=message;

                                        }else{

                                            errormessage+=" , "+message;

                                        }


                                    }


                                    t.messageerror=errormessage;

                                }
                                else{
                                    t.messageerror=res.data.message
                                }


                                toastr['error'](t.messageerror,"<b></b>");
                            }


                        })
                        .catch(

                            error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                                "<b>Erreur</b>")
                        )
                        .finally(() => this.$store.dispatch('lancerchargement',false))


                },


        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>
    .modalstyle{
        max-width: 90%!important;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


    }





</style>