<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title headertitle" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="border-b-0 border-gray-200 dark:border-white/10">
                        <nav class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" aria-label="Tabs">

                            <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 active"
                                    id="hs-tab-js-behavior-item-1" data-hs-tab="#hs-tab-js-behavior-1" aria-controls="hs-tab-js-behavior-1">
                                Paramètres pour la génération des écritures de vente
                            </button>

                            <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300"
                                    id="taxedimposition-item-2" data-hs-tab="#taxedimposition-2" aria-controls="taxedimposition-2">
                                Paramètres pour la génération des écritures de réglement
                            </button>

                            <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 "
                                    id="tarificationstock-item-1" data-hs-tab="#tarificationstock-1" aria-controls="tarificationstock-1">
                                Paramètres pour la génération des écritures de dépense (caisse)
                            </button>




                        </nav>
                    </div>




                    <div class="">


                    <div id="hs-tab-js-behavior-1" role="tabpanel"
                         aria-labelledby="hs-tab-js-behavior-item-1" class="">


                        <div class="mt-3 grid lg:grid-cols-2 gap-6">



                            <div class="space-y-2">

                                <div class="grid grid-cols-12  gap-x-6 mt-3">

                                    <div class="xl:col-span-10  col-span-10" >
                                        <label >compte de  facturation par defaut</label>
                                        <input placeholder="compte de  facturation par defaut"  v-if="Items.comptefacturation===null" type="text" class="form-control" disabled>
                                        <input v-else :value="Items.comptefacturation.numero+' '+Items.comptefacturation.libelle" type="text" class="form-control" disabled>
                                    </div>
                                    <div class="xl:col-span-2  col-span-2">
                                        <button @click="btnmodalcompta('comptefacturation')"  class="mt-4 ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>


                                    </div>




                                </div>



                            </div>

                            <div class="space-y-2">

                                <div class="grid grid-cols-12  gap-x-6 mt-3">

                                    <div class="xl:col-span-10  col-span-10" >
                                        <label >compte de client par defaut</label>
                                        <input placeholder="compte de client par defaut"  v-if="Items.compteclient===null" type="text" class="form-control" disabled>
                                        <input v-else :value="Items.compteclient.numero+' '+Items.compteclient.libelle" type="text" class="form-control" disabled>
                                    </div>
                                    <div class="xl:col-span-2  col-span-2">

                                        <button @click="btnmodalcompta('compteclient')"  class="mt-4 ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>




                                    </div>




                                </div>



                            </div>

                            <div class="space-y-2">

                                <div class="grid grid-cols-12  gap-x-6 mt-3">

                                    <div class="xl:col-span-10  col-span-10" >
                                        <label >compte de TVA par defaut</label>
                                        <input placeholder="compte de TVA par defaut"  v-if="Items.comptetva===null" type="text" class="form-control" disabled>
                                        <input v-else :value="Items.comptetva.numero+' '+Items.comptetva.libelle" type="text" class="form-control" disabled>
                                    </div>
                                    <div class="xl:col-span-2  col-span-2">

                                        <button @click="btnmodalcompta('comptetva')"  class="mt-4 ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>




                                    </div>




                                </div>



                            </div>

                            <div class="space-y-2">

                                <div class="grid grid-cols-12  gap-x-6 mt-3">

                                    <div class="xl:col-span-10  col-span-10" >
                                        <label >compte d'aib par defaut</label>
                                        <input placeholder="compte d'aib par defaut"  v-if="Items.compteaib===null" type="text" class="form-control" disabled>
                                        <input v-else :value="Items.compteaib.numero+' '+Items.compteaib.libelle" type="text" class="form-control" disabled>
                                    </div>
                                    <div class="xl:col-span-2  col-span-2">

                                        <button @click="btnmodalcompta('compteaib')"  class="mt-4 ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>




                                    </div>




                                </div>



                            </div>


                        </div>





                    </div>


                    <div id="taxedimposition-2"  role="tabpanel"
                         aria-labelledby="hs-tab-js-behavior-item-2" class="hidden">


                        <div class="mt-3 grid lg:grid-cols-2 gap-6">



                            <div class="space-y-2">

                                <div class="grid grid-cols-12  gap-x-6 mt-3">

                                    <div class="xl:col-span-10  col-span-10" >
                                        <label >compte de réglement caisse par defaut</label>
                                        <input placeholder="compte de réglement caisse par defaut"  v-if="Items.comptecaissereglement===null" type="text" class="form-control" disabled>
                                        <input v-else :value="Items.comptecaissereglement.numero+' '+Items.comptecaissereglement.libelle" type="text" class="form-control" disabled>
                                    </div>
                                    <div class="xl:col-span-2  col-span-2">
                                        <button @click="btnmodalcompta('comptecaissereglement')"  class="mt-4 ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>


                                    </div>




                                </div>



                            </div>



                            <div class="space-y-2">

                                <div class="grid grid-cols-12  gap-x-6 mt-3">

                                    <div class="xl:col-span-10  col-span-10" >
                                        <label >compte de réglement banque par defaut</label>
                                        <input placeholder="compte de réglement banque par defaut"  v-if="Items.comptebanquereglement===null" type="text" class="form-control" disabled>
                                        <input v-else :value="Items.comptebanquereglement.numero+' '+Items.comptebanquereglement.libelle" type="text" class="form-control" disabled>
                                    </div>
                                    <div class="xl:col-span-2  col-span-2">

                                        <button @click="btnmodalcompta('comptebanquereglement')"  class="mt-4 ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>




                                    </div>




                                </div>



                            </div>


                        </div>






                    </div>


                      <div id="tarificationstock-1"  role="tabpanel"
                             aria-labelledby="tarificationstock-item-1" class="hidden">


                            <div class="mt-3 grid lg:grid-cols-2 gap-6">



                                <div class="space-y-2">

                                    <div class="grid grid-cols-12  gap-x-6 mt-3">

                                        <div class="xl:col-span-10  col-span-10" >
                                            <label >compte d'attente des dépense de la caisse par defaut</label>
                                            <input placeholder="compte d'attente des dépense de la caisse par defaut"  v-if="Items.comptecaissedepattente===null" type="text" class="form-control" disabled>
                                            <input v-else :value="Items.comptecaissedepattente.numero+' '+Items.comptecaissedepattente.libelle" type="text" class="form-control" disabled>
                                        </div>
                                        <div class="xl:col-span-2  col-span-2">

                                            <button @click="btnmodalcompta('comptecaissedepattente')"  class="mt-4 ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>


                                        </div>




                                    </div>



                                </div>

                                <div class="space-y-2">

                                    <div class="grid grid-cols-12  gap-x-6 mt-3">

                                        <div class="xl:col-span-10  col-span-10" >
                                            <label >compte de caisse de dépense par defaut</label>
                                            <input placeholder="compte de caisse de dépense par defaut"  v-if="Items.comptecaissedepcaisse===null" type="text" class="form-control" disabled>
                                            <input v-else :value="Items.comptecaissedepcaisse.numero+' '+Items.comptecaissedepcaisse.libelle" type="text" class="form-control" disabled>
                                        </div>
                                        <div class="xl:col-span-2  col-span-2">

                                            <button @click="btnmodalcompta('comptecaissedepcaisse')"  class="mt-4 ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>




                                        </div>




                                    </div>



                                </div>

                                <div class="space-y-2">

                                    <div class="grid grid-cols-12  gap-x-6 mt-3">

                                        <div class="xl:col-span-10  col-span-10" >
                                            <label >compte de fourniseur des dépenses par defaut</label>
                                            <input placeholder="compte de fourniseur des dépenses par defaut"  v-if="Items.comptecaissedepfournisseur===null" type="text" class="form-control" disabled>
                                            <input v-else :value="Items.comptecaissedepfournisseur.numero+' '+Items.comptecaissedepfournisseur.libelle" type="text" class="form-control" disabled>
                                        </div>
                                        <div class="xl:col-span-2  col-span-2">

                                            <button @click="btnmodalcompta('comptecaissedepfournisseur')"  class="mt-4 ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>




                                        </div>




                                    </div>



                                </div>

                                <div class="space-y-2">

                                    <div class="grid grid-cols-12  gap-x-6 mt-3">

                                        <div class="xl:col-span-10  col-span-10" >
                                            <label >compte de TVA récupérable des dépenses par defaut</label>
                                            <input placeholder="compte de TVA récupérable des dépenses par defaut"  v-if="Items.comptetvarecuperable===null" type="text" class="form-control" disabled>
                                            <input v-else :value="Items.comptetvarecuperable.numero+' '+Items.comptetvarecuperable.libelle" type="text" class="form-control" disabled>
                                        </div>
                                        <div class="xl:col-span-2  col-span-2">

                                            <button @click="btnmodalcompta('comptetvarecuperable')"  class="mt-4 ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>

                                        </div>




                                    </div>



                                </div>




                            </div>






                        </div>



                    </div>



















                </div>


                <div class="ti-modal-footer">


                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>

                    <button  class="ti-btn ti-btn-primary-full" href="javascript:void(0);" style="background: greenyellow">
                        Valider
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



    <modalcompta @elementSelectionnecompta="handleElementSelectionnecompta" ref="modalcompta"></modalcompta>



</template>

<script>


    const axios =require('axios');


    import {FormatNumber, groupByKey, moisEnLettre, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    import modalcompta from "@/components/views/pages/stock/articles/modal_compta";


    export default {
        /* eslint-disable */
        name: "formparam",

        watch: {



        },
        computed:{



        },
        components: {
            modalcompta

        },

        data() {
            return {
                isdisplay:false,
                titlemodal:"",


                Items:{
                    pid:"",
                    comptefacturation:null,
                    compteclient:null,
                    comptetva:null,
                    compteaib:null,

                    comptebanquereglement:null,
                    comptecaissereglement:null,

                    comptecaissedepattente:null,
                    comptecaissedepfournisseur:null,

                    comptetvarecuperable:null,
                    compteaibrecuperable:null,


                    comptecaissedepcaisse:null,

                    //compteclientreglement:null,

                },

                dataplancomptable:[]


            };
        },
        methods: {


            btnmodalcompta(typ){

                let listedatable=[],
                    titlemodal="Plan comptable",
                    typedoc=typ;

                listedatable=this.dataplancomptable

                this.$refs.modalcompta.show(titlemodal,listedatable,typedoc);

            },

            handleElementSelectionnecompta(elementSelectionne) {
                // Gérer l'élément sélectionné reçu de ModalComponent

                console.log("elementSelectionne.value ***** ",elementSelectionne.value)

/*

                Items:{
                    pid:"",
                        comptefacturation:null,
                        compteclient:null,
                        comptetva:null,
                        compteaib:null,

                        comptebanquereglement:null,
                        comptecaissereglement:null,

                        comptecaissedepattente:null,
                        comptecaissedepfournisseur:null,

                        comptetvarecuperable:null,
                        compteaibrecuperable:null,


                        comptecaissedepcaisse:null,

                    //compteclientreglement:null,

                },

                */

                if (elementSelectionne.typ==="comptefacturation"){
                    this.Items.comptefacturation=elementSelectionne.value
                }

                if (elementSelectionne.typ==="compteclient"){
                    this.Items.compteclient=elementSelectionne.value
                }

                if (elementSelectionne.typ==="comptetva"){
                    this.Items.comptetva=elementSelectionne.value
                }

                if (elementSelectionne.typ==="compteaib"){
                    this.Items.compteaib=elementSelectionne.value
                }

                if (elementSelectionne.typ==="comptebanquereglement"){
                    this.Items.comptebanquereglement=elementSelectionne.value
                }

                if (elementSelectionne.typ==="comptecaissereglement"){
                    this.Items.comptecaissereglement=elementSelectionne.value
                }

                if (elementSelectionne.typ==="comptecaissedepattente"){
                    this.Items.comptecaissedepattente=elementSelectionne.value
                }

                if (elementSelectionne.typ==="comptecaissedepfournisseur"){
                    this.Items.comptecaissedepfournisseur=elementSelectionne.value
                }

                if (elementSelectionne.typ==="comptetvarecuperable"){
                    this.Items.comptetvarecuperable=elementSelectionne.value
                }

                if (elementSelectionne.typ==="compteaibrecuperable"){
                    this.Items.compteaibrecuperable=elementSelectionne.value
                }

                if (elementSelectionne.typ==="comptecaissedepcaisse"){
                    this.Items.comptecaissedepcaisse=elementSelectionne.value
                }









            },






            async load(){



                if (this.datedebut===null || this.datedebut==="" ||
                    this.datefin===null || this.datefin==="" )
                {

                    return

                }


                this.$store.dispatch('lancerchargement',true)

                const t = this;

                let url=process.env.VUE_APP_API_URL+'/compta/edition/camonth/'+this.datedebut+'/'+this.datefin+'/'+this.$store.state.appsociete+'/'+randomString();

                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){
                           /* t.datatable=[];

                            t.datatable=res.data.dt1;*/



                            let $groupebyyearmonth=groupByKey(res.data.datas,'yearandmonth');
                            var $datares=[];
                            var $datares2=[];


                            t.dmontanttotalht=0
                            t.dmontanttotaltva=0
                            t.dmontanttotalaib=0
                            t.dmontanttotal=0
                            t.dmontanttotalremise=0


                            for ( let [$index, $lineyearmonth]   of Object.entries($groupebyyearmonth)){

                                let datavalues=[];



                                let $groupebynormalisation=groupByKey($lineyearmonth,'normalisation');




                                for ( let [$indexnormalisation, $linenormalisation]   of Object.entries($groupebynormalisation)){ //grouper les facture suivants letat de normalisation


                                    let $datanormalisation=[],
                                        $moisenlettre="",
                                        $linenormalisationmontantttc=0,
                                        $linenormalisationmontantht=0,
                                        $linenormalisationmontanttva=0,
                                        $linenormalisationmontantaib=0,
                                        $linenormalisationremise=0;


                                    for ( let $line of $linenormalisation){ // calcul du CA

                                        $linenormalisationmontantttc=parseFloat($linenormalisationmontantttc)+parseFloat($line.mtntttc);
                                        $linenormalisationmontantht=parseFloat($linenormalisationmontantht)+parseFloat($line.mtntht);
                                        $linenormalisationmontanttva=parseFloat($linenormalisationmontanttva)+parseFloat($line.tva);
                                        $linenormalisationmontantaib=parseFloat($linenormalisationmontantaib)+parseFloat($line.mtntaib);
                                        $linenormalisationremise=parseFloat($linenormalisationremise)+parseFloat($line.mtntremise);


                                        t.dmontanttotal=parseFloat(t.dmontanttotal)+parseFloat($line.mtntttc);
                                        t.dmontanttotalht=parseFloat(t.dmontanttotalht)+parseFloat($line.mtntht);
                                        t.dmontanttotaltva=parseFloat(t.dmontanttotaltva)+parseFloat($line.tva);
                                        t.dmontanttotalaib=parseFloat(t.dmontanttotalaib)+parseFloat($line.mtntaib);
                                        t.dmontanttotalremise=parseFloat(t.dmontanttotalremise)+parseFloat($line.mtntremise);



                                        $moisenlettre=moisEnLettre($line.moisdatecreatedoc)+" "+$line.yeardatecreatedoc
                                    }


                                    /*  datavalues.push({
                                          montantht:$linenormalisationmontantht,
                                          montanttva:$linenormalisationmontanttva,
                                          montantaib:$linenormalisationmontantaib,
                                          remise:$linenormalisationremise,
                                          libelle:$moisenlettre,
                                          normalisation:$indexnormalisation,
                                      })*/
                                    $datares.push({
                                        montantttc:$linenormalisationmontantttc,
                                        montantht:$linenormalisationmontantht,
                                        montanttva:$linenormalisationmontanttva,
                                        montantaib:$linenormalisationmontantaib,
                                        remise:$linenormalisationremise,
                                        libelle:$moisenlettre,
                                        normalisation:$indexnormalisation,
                                    })
                                }


                            }

                            t.montanttotalht=0
                            t.montanttotaltva=0
                            t.montanttotalaib=0
                            t.montanttotal=0


                            $datares.forEach(function ($line) {

                                t.montanttotalht=parseFloat($line.montantht)+parseFloat(t.montanttotalht)
                                t.montanttotaltva=parseFloat($line.montanttva)+parseFloat(t.montanttotaltva)
                                t.montanttotalaib=parseFloat($line.montantaib)+parseFloat(t.montanttotalaib)
                                t.montanttotal=parseFloat($line.montantttc)+parseFloat(t.montanttotal)

                            })


                            t.tableview1=$datares


                            t.tableview2=[];

                            t.tableview2=res.data.datas


                        }
                        else{
                            t.tableview1=[];
                            t.tableview2=[];
                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })




            },


            async loadcomptecomptable(){

                const t = this;

                let url=process.env.VUE_APP_API_URL+'/compta/def/compte/comptable/'+this.$store.state.appsociete+'/'+randomString();

                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    } ,withCredentials: true // Permet l'envoi de cookies et autres informations d'authentification
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){

                            t.dataplancomptable=res.data.m

                        }
                        else{
                            t.dataplancomptable=[];
                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })




            },

            close(){

                this.isdisplay=false;

                //this.$emit('closed');

            },

            show(){


                this.messagesucces="";
                this.messageerror="";

                this.loadcomptecomptable()

              this.titlemodal=" Paramètres comptable " ;


                this.isdisplay=true;





            },


            FormatNumber(num) {

                return  FormatNumber(num)
            },




        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>


    .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 99%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>